/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '@ionic/angular/css/normalize.css';
@import '@ionic/angular/css/structure.css';
@import '@ionic/angular/css/typography.css';
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '@ionic/angular/css/padding.css';
@import '@ionic/angular/css/float-elements.css';
@import '@ionic/angular/css/text-alignment.css';
@import '@ionic/angular/css/text-transformation.css';
@import '@ionic/angular/css/flex-utils.css';

/* Swiper */
@import 'swiper/scss';
@import 'swiper/scss/pagination';
@import 'swiper/scss/effect-creative';
@import '@ionic/angular/css/ionic-swiper';

// Fonts
@font-face {
  font-family: 'Work Sans';
  src: url('./assets/fonts/work-sans-latin-regular.woff') format('woff');
  font-weight: 500;
}

* {
  font-family: 'Work Sans', sans-serif;
  --ion-font-family: 'Work Sans', sans-serif;
}

ion-content {
  --background: var(--ion-color-secondary);
}

ion-list {
  background: var(--ion-color-secondary) !important;
}


// textarea

ion-textarea {
  height: 200px !important;
}

//INPUT

ion-input.ng-valid {
  input.native-input {
    caret-color: var(--ion-color-primary) !important;
  }
}

.ng-valid .label-text {
  color: var(--ion-color-primary);
}

.ng-dirty:not(form) {
  margin-bottom: 0px;
  height: auto;
}

.ng-invalid:not(form).ng-touched {
  margin-bottom: 0px;
  height: auto;
}

ion-item {
  --background: none;
  --color: var(--ion-color-light);
  cursor: pointer;
}

.stop-input {
  margin-top: 10px;
  --padding-top: 3px;
  --padding-bottom: 3px;
  --background: var(--ion-color-tertiary);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  outline: none;
  border: none;

  ion-input {
    --border-color: none !important;
  }
}

//BUTTON

.stop-button-primary {
  margin-top: 20px;
  height: 60px;
  --border-radius: 10px;
  -webkit-border-radius: 10px;
  --ripple-color: transparent;
  text-transform: none;
  border: 1.5px solid var(--ion-color-primary);
  --background: none;
  --color: var(--ion-color-primary);
  font-size: 16px;
  --box-shadow: none;
  --background-activated: transparent;
  --background-focused: transparent;
  --background-hover: transparent;
}

.stop-button-primary:active {
  --box-shadow: 0 5px 40px -20px var(--ion-color-primary);
}


.stop-button-danger {
  margin-top: 20px;
  height: 60px;
  --border-radius: 10px;
  -webkit-border-radius: 10px;
  --ripple-color: var(--ion-color-danger);
  text-transform: none;
  border: 1.5px solid var(--ion-color-danger);
  --background: none;
  --color: var(--ion-color-danger);
  font-size: 16px;
  --box-shadow: none;
  --background-activated: transparent;
  --background-focused: transparent;
  --background-hover: transparent;
}



.stop-button-secondary {
  margin-top: 20px;
  --border-radius: 10px;
  -webkit-border-radius: 10px;
  --ripple-color: transparent;
  text-transform: none;
  font-size: 16px;
  --box-shadow: none;
  text-decoration: underline var(--stop-color-secondary-0) 1px;
  animation: underlinefade 0.7s ease-in-out 0.3s 1 normal forwards;
}

@keyframes underlinefade {
  0% {
    text-decoration: underline var(--stop-color-secondary-0) 2px;
  }

  100% {
    text-decoration: underline var(--stop-color-primary-10) 2px;
  }
}

.stop-button-login {
  margin-top: 30px;
  height: 60px;
  --border-radius: 10px;
  -webkit-border-radius: 10px;
  --ripple-color: var(--ion-color-primary);
  text-transform: none;
  --background: var(--stop-color-tertiary-25);
  --color: var(--ion-color-primary);
  font-size: 16px;
  --box-shadow: none;
}

.stop-button-login:last-child {
  margin-top: 15px;
}

ion-button {
  transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
}

.stop-card:hover,
ion-button:hover {
  transition: 0.2s;
}

.stop-card:active,
ion-button:active {
  transform: scale(0.98);
  box-shadow: 0 2px 10px 0 var(--ion-color-tertiary);
}

// spacing

.stop-no-margin {
  margin: 0 !important;
}

.stop-margin-top {
  margin-top: 16px !important;
}

.stop-margin-bottom {
  margin-bottom: 16px !important;
}

.stop-less-margin-left {
  margin-left: -10px !important;
}

ion-content {
  --padding-bottom: 120px !important;
}

.stop-no-navbar {
  --padding-bottom: 16px !important;
}

// bottom sheet

.bottom-sheet-background {
  position: fixed;
  top: 70px;
  left: 0;
  height: 100vh;
  width: 100vw;
}

.stop-bottom-sheet {
  margin-top: 55vh;
  background-color: var(--ion-color-secondary);
  position: relative;
  z-index: 9;
  border-radius: 20px 20px 0 0;
  padding: 20px;
  box-shadow: 0px -10px 10px var(--stop-color-tertiary-25);
  animation: bottom-sheet 1s ease 0s 1 normal none;

}

.stop-bottom-sheet-handle {
  height: 7px;
  width: 30%;
  background-color: var(--stop-color-light-50);
  opacity: 20%;
  border-radius: 99px;
  margin: 0 auto 20px auto;
}

.ios .stop-bottom-sheet {
  margin-top: calc(55vh - 100px);
  box-shadow: 0px 400px var(--ion-color-secondary);
}

// IMAGE
.stop-image {
  max-width: 300px;
  width: 45%;
  margin-top: 30px;
  margin-bottom: 45px;
  margin-left: auto;
  margin-right: auto;
}

// pagination

.ngx-pagination {
  transform: translateX(-20px);

  .disabled {
    opacity: 30%;
  }
}


// PROGRESS BAR

ion-progress-bar {
  --background: var(--ion-color-primary);
  --progress-background: var(--ion-color-tertiary);
  border-radius: 9px !important;
  margin-top: 15px;
}

// Label

.stop-label-large {
  --color: var(--ion-color-primary);
  color: var(--ion-color-primary);
  font-size: 18px !important;
  font-weight: 600 !important;
}

.stop-label {
  font-size: 16px !important;
  --color: var(--ion-color-light);
  color: var(--ion-color-light);
}

.stop-label-small {
  margin-top: 5px !important;
  font-size: 14px !important;
  opacity: 50% !important;
  color: var(--ion-color-light) !important;
  white-space: pre-line !important;
  line-height: 120% !important;
}

.stop-label-smaller {
  margin-top: 5px !important;
  font-size: 10px !important;
  opacity: 50% !important;
  color: var(--ion-color-light) !important;
  white-space: pre-line !important;
  line-height: 110% !important;
}



.stop-color-light {
  --color: var(--ion-color-light);
  color: var(--ion-color-light) !important;
}

.stop-color-success {
  --color: var(--ion-color-success);
  color: var(--ion-color-success) !important;
}

.stop-color-danger {
  --color: var(--ion-color-danger);
  color: var(--ion-color-danger) !important;
}

.stop-one-line {
  text-overflow: inherit !important;
  white-space: nowrap !important;
  overflow: inherit !important;
}

// Toggle

ion-toggle {
  --track-background-checked: var(--ion-color-tertiary);
  --handle-background: var(--stop-color-light-50);
  --handle-background-checked: var(--ion-color-primary);
  --handle-width: 20px;
  --handle-height: 20px;
  --handle-spacing: 5px;

  --handle-border-radius: 99px;
  --handle-box-shadow: none;
}

ion-toggle::part(track) {
  height: 30px;
  width: 50px;
  overflow: visible;
}

//stop input error
.stop-input-error {
  position: absolute;
  bottom: -7px;
  left: 5px;
  font-size: 12px !important;
}


//Icon


.stop-card-icon {
  transform: translate(10px, 0);
  color: var(--ion-color-light);
  opacity: 70%;
  font-size: 37px;
  margin: 0;
}

.stop-icon-grey {
  color: var(--ion-color-light);
  opacity: 70%;
}

.stop-card-image-icon {
  height: 60px;
  width: auto;
  margin-top: 10px;
  margin-bottom: 10px;
}

.stop-card-spinner {
  opacity: 70%;
  width: 35px;
}

.stop-left-icon {
  color: var(--ion-color-light);
  opacity: 70%;
  font-size: 25px;
  margin-right: 10px;
}

.stop-input-error {
  color: var(--ion-color-danger);
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 5px;
  display: block;
}

.stop-input-icon {
  color: var(--ion-color-light);
  opacity: 70%;
  font-size: 24px;
  margin-bottom: 30px;
  transform: translate(0, 15px);
  float: right;
  right: 20px;
  display: block;
  position: absolute;
}

.ion-invalid.ion-touched {
  .stop-input-icon {
    color: var(--ion-color-danger);
  }

  .stop-type-toggle {
    ion-icon {
      color: var(--ion-color-danger);
    }
  }
}

// Action Sheet
.action-sheet-wrapper {
  .action-sheet-title {
    color: var(--ion-color-light) !important;
  }

  .action-sheet-button {
    color: var(--ion-color-primary) !important;
  }

  .action-sheet-group {
    background: var(--ion-color-tertiary) !important;
  }

  .action-sheet-group-cancel {
    .action-sheet-button {
      &::after {
        background: var(--ion-color-tertiary) !important;
      }
    }
  }
}

// Footer
ion-footer {
  background: var(--stop-color-tertiary-80) !important;

  &.ios {
    .footer-background {
      backdrop-filter: none !important;
      -webkit-backdrop-filter: none !important;
    }

    ion-button {
      margin-bottom: 10px !important;
    }
  }
}

// segments

ion-segment ion-label {
  font-weight: bold !important;
  text-transform: none !important;
  font-size: 16px;
}

ion-segment-button {
  --color: var(--ion-color-medium);
}

.ios ion-segment-button {
  --indicator-color: var(--ion-color-primary) !important;
  --padding-bottom: 10px !important;
  --padding-top: 10px !important;
  --color-checked: var(--ion-color-dark) !important;
}

// input error messages
ion-input .error-text,
ion-input .helper-text {
  margin-bottom: 9px;
}

//Card

.stop-card {
  margin: 0;
  --background: var(--stop-color-tertiary-25);
  --color: var(--ion-color-light);
  box-shadow: none !important;
  padding: 10px 15px !important;
  border-radius: 10px !important;
}

// Refresher
ion-refresher ion-refresher-content {
  --color: var(--ion-color-light) !important;

  .refresher-pulling-text,
  .refresher-refreshing-text,
  .refresher-refreshing-icon,
  .refresher-pulling-icon {
    color: var(--ion-color-light) !important;
  }
}

// Divider

ion-item-divider {
  --background: var(--stop-color-transparent);
  border-width: 1.5px !important;
  border-color: var(--ion-color-light);
  opacity: 10% !important;
}


ion-item {
  --border-color: rgba(255, 255, 255, 0.1);
}

// Checkbox

.stop-checkbox {
  --checkbox-background: var(--ion-color-secondary);
  --border-color: var(--stop-color-light-50);
  --border-radius: 4px;
  --checkbox-background-checked: var(--ion-color-primary);
  --border-color-checked: var(--ion-color-primary);
  --border-width: 1.5px;
  --checkmark-color: var(--ion-color-tertiary);
  --checkmark-width: 3px;
  --size: 18px;
  --transition: 0.5s;
}

// Radio

.stop-radio {
  --background: var(--ion-color-secondary);
  --border-color: var(--stop-color-light-50);
  --background-checked: var(--ion-color-primary);
  --border-color-checked: var(--ion-color-primary);
  --border-width: 1.5px;
  --size: 18px;
  --transition: 0.5s;
}

//setup pages
.stop-setup-page,
.large-text-page {
  .stop-label-large {
    display: block;
    margin-bottom: 10px;
  }

  .stop-title {
    font-size: 36px !important;
    margin-bottom: 15px;
    font-weight: 600 !important;
  }

  .stop-leadtext {
    font-size: 18px !important;
    display: inline-block;
    line-height: 140% !important;
  }

  .stop-buttons-bottom {
    height: calc(100% - 16px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .stop-button-secondary {
    margin-top: 15px;
    font-size: 17px !important;
    margin-bottom: 15px;
  }

  .stop-leadtext-size {
    font-size: 18px !important;
  }
}



.stop-setup-page {
  --padding-bottom: 0px !important;
  --background: var(--ion-color-tertiary) !important;

  .stop-progress-0 {
    .stop-setup-progress:before {
      width: 0%;
    }
  }

  .stop-progress-25 {
    .stop-setup-progress:before {
      animation: setupprogress25 0.75s ease forwards;
    }
  }

  .stop-progress-25-no-animation {
    .stop-setup-progress:before {
      width: 25%;
    }
  }


  @keyframes setupprogress25 {
    0% {
      width: 0%;
    }

    100% {
      width: 25%;
    }
  }


  .stop-progress-50 {
    .stop-setup-progress:before {
      animation: setupprogress50 0.75s ease forwards;
    }
  }



  @keyframes setupprogress50 {
    0% {
      width: 25%;
    }

    100% {
      width: 50%;
    }
  }

  .stop-progress-75 {
    .stop-setup-progress:before {
      animation: setupprogress75 0.75s ease forwards;
    }
  }

  .stop-progress-75-no-animation {
    .stop-setup-progress:before {
      width: 75%;
    }
  }

  @keyframes setupprogress75 {
    0% {
      width: 50%;
    }

    100% {
      width: 75%;
    }
  }

  .stop-progress-100 {
    .stop-setup-progress:before {
      animation: setupprogress25 0.75s ease forwards;
    }
  }


  @keyframes setupprogress100 {
    0% {
      width: 75%;
    }

    100% {
      width: 100%;
    }
  }


  ion-card {
    background-color: var(--ion-color-secondary) !important;
  }

}


//stop empty card

.stop-empty-card {
  height: auto !important;
  padding: 15px !important;
}

//empty screen variant 2

.stop-empty-variant-2 {
  .stop-empty-description {
    text-align: center;
  }

  ion-img {
    zoom: 3;
    margin-top: -5px !important;
    margin-bottom: 5px !important;
  }
}

// guard mode warning cards

.stop-guard-warning ion-card {
  animation: errorin 0.5s ease-in-out 0s 1 normal forwards;
  justify-content: center;
  align-items: center;
  display: grid;
  margin-bottom: 10px !important;
}

.stop-guard-warning ion-label {
  width: 100%;
}

@keyframes errorin {
  0% {
    transform: scale(1.02);
    opacity: 0%;
  }

  100% {
    transform: scale(1);
    opacity: 100%;
  }
}

// ion-item-sliding

ion-item-sliding * {
  border: none !important;
  outline: none !important;
}

//contact and register form paragraphs

.stop-form-paragraph p {
  --color: var(--ion-color-light);
  color: var(--ion-color-light);
  font-size: 14px;
  line-height: 1.5;
  padding-left: 5px;
}

// IOS BUGS

.ios .ios-bug-fix ion-label {
  margin-top: 0px !important;
  transform: translateY(-3px);
}


.ios .stop-guard-active-bar {
  top: 140px !important;
}

.ios .stop-setup-page .stop-buttons-bottom,
.ios .large-text-page .stop-buttons-bottom,
.ios .stop-ios-statusbar-bugfix {
  margin-top: 50px;
  height: calc(100vh - 80px);
}

.ios .stop-setup-page .stop-title:after,
.ios .large-text-page .stop-title:after {
  content: "";
  width: 200%;
  height: 55px;
  position: fixed;
  top: 0px;
  left: -20px;
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  background: var(--stop-color-tertiary-80);
}

.ios .stop-setup-page .stop-title:before,
.ios .large-text-page .stop-title:before {
  content: "";
  width: 200%;
  height: 43px;
  position: fixed;
  bottom: -20px;
  z-index: 999;
  left: -20px;
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  background: var(--stop-color-tertiary-80);
}

.ios .contains-tabbar .stop-title:before {
  display: none;
}

.ios .ios-standard-statusbar-bg .stop-title:before,
.ios .ios-standard-statusbar-bg .stop-title:after,
{
display: none;
}

.ios .stop-gradient {
  bottom: 10px !important;
}

//opacity 
.stop-opacity-100 {
  opacity: 100% !important;
}


// contacts import page input width bug 

.stop-input-width-bugfix input {
  padding-right: 5px !important;
}

//success card
.stop-light-card {
  background-color: transparent;
  border: 1px solid var(--ion-color-secondary-tint);
}

// line break if text is to long

.stop-linebreak {
  white-space: normal;
  word-break: break-word;
}

// Animations
ion-content {
  &.md {
    animation: global 0.4s ease 0s 1 normal none;
  }
}

ion-card {
  animation: cards 0.5s ease 0s 1 normal none;
}

ion-button {
  animation: cards 0.5s ease 0s 1 normal none;
}

.stop-image {
  animation: cards 0.5s ease 0s 1 normal none;
}

.stop-input {
  animation: input 0.5s ease 0s 1 normal none;
}

ion-tabs,
ion-fab-button,
ion-tab-bar {
  animation: tab-bar 0.5s ease 0s 1 normal none;
}

ion-fab-button {
  animation: fab 0.5s ease 0s 1 normal none;
}

ion-slide img {
  animation: cards 0.5s ease 0s 1 normal none;
}

@keyframes global {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes cards {
  0% {
    opacity: 0;
    transform: scale(0.98);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes input {
  0% {
    opacity: 0;
    transform: translateY(7px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes tab-bar {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes bottom-sheet {
  0% {
    transform: translateY(-100px)
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes fab {
  0% {
    opacity: 0;
    transform: translateY(40px);
    scale: 1.3;
  }

  50% {
    scale: 1.15;
  }

  100% {
    opacity: 1;
    transform: translateY(0);
    scale: 1;
  }
}

@keyframes check {
  0% {
    opacity: 0;
    transform: scale(1.2);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

// Background animations


.stop-color-animation-primary {
  animation: colors 1.5s ease-in-out 0s infinite alternate-reverse forwards;
  background: var(--ion-color-primary);
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: -999;
}


@keyframes colorsalarm {
  0% {
    opacity: 5%;
  }

  100% {
    opacity: 30%;
  }
}

@keyframes colors {
  0% {
    opacity: 5%;
  }

  100% {
    opacity: 13%;
  }
}

@keyframes notification-alarm-danger {
  0% {
    background-color: var(--ion-color-danger);
  }

  100% {
    background-color: var(--ion-color-warning);
  }
}

// Responsive design
@media screen and (min-width: 600px) {
  .stop-image {
    max-width: 300px;
    width: 30%;
  }
}

.loading-wrapper {
  background-color: var(--ion-color-secondary) !important;
  color: var(--ion-color-light);
  border-radius: 15px !important;
}


.vertical-slider {
  width: 100% !important;
  height: 100% !important;
  position: relative;
  overflow: hidden;
  --swiper-theme-color: #fff;

  .swiper-pagination-bullet {
    background: #fff;
  }

  .swiper {
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .slide-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 16px;
    text-align: center;
  }

  .vertical-slider-fills {
    left: 50%;
    top: 50%;
    z-index: 0;
  }

  .vertical-slider-fills,
  .vertical-slider-fill {
    position: absolute;
    pointer-events: none;
    width: 100%;
    height: 100%;
  }

  .vertical-slider-fill {
    left: 0;
    top: 0;
    transform-origin: center center;
    border-radius: 50%;
  }
}