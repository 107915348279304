/**
 * Convert a font size to a dynamic font size.
 * Fonts that participate in Dynamic Type should use
 * dynamic font sizes.
 * @param size - The initial font size including the unit (i.e. px or pt)
 * @param unit (optional) - The unit to convert to. Use this if you want to
 * convert to a unit other than $baselineUnit.
 */
/**
 * Convert a font size to a dynamic font size but impose
 * a maximum font size.
 * @param size - The initial font size including the unit (i.e. px or pt)
 * @param maxScale - The maximum scale of the font (i.e. 2.5 for a maximum 250% scale).
 * @param unit (optional) - The unit to convert the initial font size to. Use this if you want to
 * convert to a unit other than $baselineUnit.
 */
/**
 * Convert a font size to a dynamic font size but impose
 * a minimum font size.
 * @param size - The initial font size including the unit (i.e. px or pt)
 * @param minScale - The minimum scale of the font (i.e. 0.8 for a minimum 80% scale).
 * @param unit (optional) - The unit to convert the initial font size to. Use this if you want to
 * convert to a unit other than $baselineUnit.
 */
/**
 * Convert a font size to a dynamic font size but impose
 * maximum and minimum font sizes.
 * @param size - The initial font size including the unit (i.e. px or pt)
 * @param minScale - The minimum scale of the font (i.e. 0.8 for a minimum 80% scale).
 * @param maxScale - The maximum scale of the font (i.e. 2.5 for a maximum 250% scale).
 * @param unit (optional) - The unit to convert the initial font size to. Use this if you want to
 * convert to a unit other than $baselineUnit.
 */
/**
 * A heuristic that applies CSS to tablet
 * viewports.
 *
 * Usage:
 * @include tablet-viewport() {
 *   :host {
 *     background-color: green;
 *   }
 * }
 */
/**
 * A heuristic that applies CSS to mobile
 * viewports (i.e. phones, not tablets).
 *
 * Usage:
 * @include mobile-viewport() {
 *   :host {
 *     background-color: blue;
 *   }
 * }
 */
.swiper {
  --bullet-background: $text-color-step-800;
  --bullet-background-active: ion-color(primary, base);
  --progress-bar-background: rgba($text-color-rgb, .25);
  --progress-bar-background-active: ion-color(primary, shade);
  --scroll-bar-background: rgba($text-color-rgb, .1);
  --scroll-bar-background-active: rgba($text-color-rgb, .5);
  /**
   * @prop --bullet-background: Background of the pagination bullets
   * @prop --bullet-background-active: Background of the active pagination bullet
   *
   * @prop --progress-bar-background: Background of the pagination progress-bar
   * @prop --progress-bar-background-active: Background of the active pagination progress-bar
   *
   * @prop --scroll-bar-background: Background of the pagination scroll-bar
   * @prop --scroll-bar-background-active: Background of the active pagination scroll-bar
   */
  display: block;
  user-select: none;
}

.swiper .swiper-pagination-bullet {
  background: var(--bullet-background);
}

.swiper .swiper-pagination-bullet-active {
  background: var(--bullet-background-active);
}

.swiper .swiper-pagination-progressbar {
  background: var(--progress-bar-background);
}

.swiper .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background: var(--progress-bar-background-active);
}

.swiper .swiper-scrollbar {
  background: var(--scroll-bar-background);
}

.swiper .swiper-scrollbar-drag {
  background: var(--scroll-bar-background-active);
}

.swiper .slide-zoom {
  display: block;
  width: 100%;
  text-align: center;
}

.swiper .swiper-slide {
  display: flex;
  position: relative;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: 18px;
  text-align: center;
  box-sizing: border-box;
}

.swiper .swiper-slide img {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
}

/*# sourceMappingURL=ionic-swiper.css.map */
