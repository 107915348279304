// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
    --ion-color-primary: #FFEC01;
    --ion-color-primary-rgb: 255, 236, 1;
    --ion-color-primary-contrast: #000000;
    --ion-color-primary-contrast-rgb: 0, 0, 0;
    --ion-color-primary-shade: #e0d001;
    --ion-color-primary-tint: #ffee1a;

    --ion-color-secondary: #323231;
    --ion-color-secondary-rgb: 50, 50, 49;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #2c2c2b;
    --ion-color-secondary-tint: #474746;

    --ion-color-tertiary: #191816;
    --ion-color-tertiary-rgb: 25, 24, 22;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #161513;
    --ion-color-tertiary-tint: #302f2d;

    --ion-color-success: #2DD552;
    --ion-color-success-rgb: 45, 211, 111;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0, 0, 0;
    --ion-color-success-shade: #28ba62;
    --ion-color-success-tint: #42d77d;

    --ion-color-warning: #ffc409;
    --ion-color-warning-rgb: 255, 196, 9;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0ac08;
    --ion-color-warning-tint: #ffca22;

    --ion-color-danger: #E53054;
    --ion-color-danger-rgb: 255, 56, 100;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #e03158;
    --ion-color-danger-tint: #ff4c74;

    --ion-color-medium: #8F8F8E;
    --ion-color-medium-rgb: 143, 143, 142;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0, 0, 0;
    --ion-color-medium-shade: #7e7e7d;
    --ion-color-medium-tint: #9a9a99;

    --ion-color-light: #f7f6f2;
    --ion-color-light-rgb: 255, 255, 255;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #e0e0e0;
    --ion-color-light-tint: #ffffff;

    --stop-color-tertiary-80: hsla(30, 4%, 9%, 0.8);
    --stop-color-tertiary-25: hsla(30, 4%, 9%, 0.25);
    --stop-color-light-50: rgba(247, 246, 242, 0.5);
    --stop-color-secondary-80: hsla(60, 1%, 19%, 0.8);
    --stop-color-secondary-30: hsla(60, 1%, 19%, 0.3);
    --stop-color-secondary-0: hsla(60, 1%, 19%, 0.0);
    --stop-color-primary-dark: #2c2a0f;
    --stop-color-primary-10: rgba(255, 238, 1, 0.1);
    --stop-color-black: rgb(0, 0, 0);

}